import React, { Component } from 'react';
import "./CrudTable.css";
import Redirect from "react-router-dom/es/Redirect";
import axios from "axios";
import AAppBar from "../AdminAppBar/AAppBar";
import SideNav, {NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';

export default class CrudTable extends Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            users: [],
            auth: true,
            deletedStatus: false,
            redirectionFlag: false
        }

        this.getUser = this.getUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentWillMount()
    {
        if (sessionStorage.getItem("apiToken"))
        {
            if (sessionStorage.getItem("adminUser") == "T")
            {
                this.setState({
                    redirectionFlag: true
                });
                this.getUser();
            }
            else
            {
                this.setState({
                    redirectionFlag: false
                });
            }
        }
        else
        {
            this.setState({
                redirectionFlag: false
            });
        }
    }

    getUser()
    {
        let auth = this.state.auth;

        var apiurl = process.env.REACT_APP_BASE_URL;
        apiurl = apiurl + '/user-management/admin/get-users/';
        var self = this
        let users = this.state.users
        let token = sessionStorage.getItem("apiToken");
        let headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        let payload = {
            auth
        };

        axios
            .post(apiurl, payload, {headers: headers})
            .then(function(response)
            {
                if(response.data.statusCode === 200)
                {
                    self.setState({
                        users: response.data.customers
                    });
                    self.getUser();
                }
            })
            .catch(function (error)
            {
                console.log((error.message))
            });
    }

    deleteUser(userId)
    {
        let auth = this.state.auth;

        var apiurl = process.env.REACT_APP_BASE_URL;
        apiurl = apiurl + '/user-management/admin/delete-user/' + userId + '/';
        var self = this;
        let token = sessionStorage.getItem("apiToken");
        let headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        let payload = {
            auth
        };

        axios
            .post(apiurl, payload, { headers: headers })
            .then( function(response)
            {
                if(response.data.statusCode === 200)
                {
                    self.setState({
                        deletedStatus: true
                    });
                }
                else
                {
                    self.setState({
                        deletedStatus: false
                    });
                }
            })
            .catch( function (error)
            {
                console.log(error.message);
            });
    }

    render()
    {
        if (this.state.redirectionFlag)
        { 
            return(
                <div>
                    <AAppBar/>
                    <SideNav
                            style={{
                                backgroundColor: '#7eb0c7',
                                top: '10.6%'
                            }}
                            onSelect={(selected) => {
                            }}
                        >
                            <SideNav.Toggle/>
                            <SideNav.Nav defaultSelected="home">
                                <NavItem eventKey="charts">
                                    <NavIcon>
                                        <i className="fa fa-fw fa-user" style={{fontSize: '1.75em', color: '#fff'}}/>
                                    </NavIcon>
                                    <NavText style={{
                                        color: '#fff'
                                    }}>
                                        Manage User
                                    </NavText>
                                    <NavItem eventKey="charts/linechart">
                                        <NavText>
                                            <a href={"/adduser"}>Add New User</a>
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="charts/linechart">
                                        <NavText>
                                            <a href={"/userlist"}>View Users</a>
                                        </NavText>
                                    </NavItem>
                                </NavItem>
                            </SideNav.Nav>
                        </SideNav>
                    <center>
                        <div className="col-sm-10">
                            <table className="table table-striped">
                                <thead className = "text-center">
                                    <tr>
                                        <th>User Name</th>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className = "text-center">
                                    {
                                        this.state.users.map(
                                        user =>
                                            <tr>
                                                <td>{user.userName}</td>
                                                <td>{user.emailId}</td>
                                                <td>
                                                    <button className="btn btn-danger" onClick = {() => this.deleteUser(user.id)} >Delete</button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </center>
                </div>
            )
            
        }
    }
}
