import React, {Component} from 'react';
import AAppBar from "../AdminAppBar/AAppBar";
import Redirect from "react-router-dom/es/Redirect";
import "./Admin.css";
import SideNav, {Toggle, Nav, NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

class Admin extends Component
{
    constructor(props)
    {
        super(props)
    }

    componentWillMount()
    {
        if (sessionStorage.getItem("apiToken"))
        {
            console.log(sessionStorage.getItem("adminUser"));
            if (sessionStorage.getItem("adminUser") == "T")
            {
                this.setState({
                    redirectionFlag: true
                });
            }
            else
            {
                this.setState({
                    redirectionFlag: false
                });
            }
        }
        else
        {
            this.setState({
                redirectionFlag: false
            });
        }
    }

    render()
    {
        if (this.state.redirectionFlag)
        {
            return(
                <div>
                    <div>
                        <AAppBar/>
                    </div>
                    <SideNav
                        style={{
                            backgroundColor: '#7eb0c7',
                            top: '10.6%'
                        }}
                        onSelect={(selected) => {
                        }}
                    >
                        <SideNav.Toggle/>
                        <SideNav.Nav defaultSelected="home">
                            <NavItem eventKey="charts">
                                <NavIcon>
                                    <i className="fa fa-fw fa-user" style={{fontSize: '1.75em', color: '#fff'}}/>
                                </NavIcon>
                                <NavText style={{
                                    color: '#fff'
                                }}>
                                    Manage User
                                </NavText>
                                <NavItem eventKey="charts/linechart">
                                    <NavText>
                                        <a href={"/adduser"}>Add New User</a>
                                    </NavText>
                                </NavItem>
                                <NavItem eventKey="charts/linechart">
                                    <NavText>
                                        <a href={"/userlist"}>View Users</a>
                                    </NavText>
                                </NavItem>
                            </NavItem>
                        </SideNav.Nav>
                    </SideNav>
                </div>
            )
        }
        else
        {
            return (
                <Redirect to={{
                    pathname: '/'
                }}/>
            )
        }
    }
}

export default Admin;
