import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom"
import './index.css';
import * as serviceWorker from './serviceWorker';
import Route from "react-router-dom/Route";

import Login from "./Component/Login/Login";
import Computation from "./Component/Computation/Computation";
import CreateUser from "./Component/Admin/Createuser/CreateUser";
import Admin from "./Component/Admin/AdminDash/Admin";
import CrudTable from "./Component/Admin/CrudTable/CrudTable"

const routing = (
    <Router>
        <Route exact path='/' component={Login}/>
        <Route path="/computation" component={Computation}/>
        <Route path={"/adduser"} component={CreateUser}/>
        <Route path={"/admin"} component = {Admin}/>
        <Route path={"/userlist"} component = {CrudTable}/>
    </Router>
);

ReactDOM.render(routing, document.getElementById('root'));
serviceWorker.unregister();
