import React, {Component} from 'react';
import "./Createuser.css"
import Redirect from "react-router-dom/es/Redirect";
import {ToastContainer, toast} from "react-toastify";
import axios from "axios";
import AAppBar from "../AdminAppBar/AAppBar";
import SideNav, {Toggle, Nav, NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';

const ErrorPasswordMatch = () => <div>Password not matching</div>;
const ErrorUsername = () => <div>Username must be specified</div>;
const ErrorEmail = () => <div>Email must be specified</div>;
const ErrorPassword = () => <div>Password must be specified</div>;
const ErrorEmailExist = () => <div>Email Id already exist</div>;
const ErrorUserNameExist = () => <div>Username already exist</div>;
const ErrorReg = () => <div>Registration failed</div>;
const Success = () => <div>Registered Successfully</div>;

class CreateUser extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            userName: '',
            password: '',
            confirmPassword: '',
            emailId: '',
            registered: false,
            redirectionFlag: false
        };
        this.onBlur = this.onBlur.bind(this);
        this.register = this.register.bind(this);
    }

    componentWillMount()
    {
        if (sessionStorage.getItem("apiToken"))
        {
            console.log(sessionStorage.getItem("adminUser"));
            if (sessionStorage.getItem("adminUser") == "T")
            {
                this.setState({
                    redirectionFlag: true
                });
            }
            else
            {
                this.setState({
                    redirectionFlag: false
                });
            }
        }
        else
        {
            this.setState({
                redirectionFlag: false
            });
        }
    }

    onBlur(e)
    {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    register()
    {
        let username = this.state.userName;
        let emailId = this.state.emailId;
        let password = this.state.password;
        let confirmPassword = this.state.confirmPassword;

        var self = this;

        if ((username === '' || username === ' ') || (password === '' ||
            password === ' ') || (emailId === '' || emailId === ' '))
        {
            if ((username === '' || username === ' '))
            {
                toast.error(<ErrorUsername/>);
            }
            if ((password === '' || password === ' '))
            {
                toast.error(<ErrorPassword/>);
            }
            if ((emailId === '' || emailId === ' '))
            {
                toast.error(<ErrorEmail/>);
            }
        }
        else {
            if (password === confirmPassword)
            {
                let token = sessionStorage.getItem("apiToken");
                let headers = {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                };

                let apiurl = process.env.REACT_APP_BASE_URL;
                apiurl = apiurl + '/user-management/admin/add-user/';

                let payload = {
                    username,
                    emailId,
                    password,
                    confirmPassword
                };

                axios
                    .post(apiurl, payload, {headers: headers})
                    .then(function (response)
                    {
                        if (response.status === 200)
                        {
                            if (response.data.statusCode == 200)
                            {
                                self.setState({
                                    registered: true
                                });
                                sessionStorage.setItem("register", JSON.stringify(self.state.registered));
                            }
                            else if (response.data.errorCode == 201)
                            {
                                toast.error(<ErrorPasswordMatch/>);
                            }
                            else if (response.data.errorCode == 202)
                            {
                                toast.error(<ErrorReg/>);
                            }
                            else if (response.data.errorCode == 203)
                            {
                                toast.error(<ErrorEmailExist/>);
                            }
                            else if (response.data.errorCode == 204)
                            {
                                toast.error(<ErrorUserNameExist/>);
                            }
                        }
                    })
                    .catch(function (error)
                    {
                        console.log((error.message));
                        toast.error(<ErrorReg/>);
                    })
            }
            else
            {
                toast.error(<ErrorPasswordMatch/>);
            }
        }
    }

    render()
    {
        if (this.state.redirectionFlag)
        {

            if (this.state.registered)
            {
                {
                    return (
                        <Redirect to={{
                            pathname: '/userlist'
                        }}/>
                    )
                }
            }
            else
            {
                return (
                    <div className={"registration-pane"}>
                        <div className={"inner-reg-pane"}>
                            <AAppBar/>
                    <SideNav
                        style={{
                            backgroundColor: '#7eb0c7',
                            top: '10.6%'
                        }}
                        onSelect={(selected) => {
                        }}
                    >
                        <SideNav.Toggle/>
                        <SideNav.Nav defaultSelected="home">
                            <NavItem eventKey="charts">
                                <NavIcon>
                                    <i className="fa fa-fw fa-user" style={{fontSize: '1.75em', color: '#fff'}}/>
                                </NavIcon>
                                <NavText style={{
                                    color: '#fff'
                                }}>
                                    Manage User
                                </NavText>
                                <NavItem eventKey="charts/linechart">
                                    <NavText>
                                        <a href={"/adduser"}>Add New User</a>
                                    </NavText>
                                </NavItem>
                                <NavItem eventKey="charts/linechart">
                                    <NavText>
                                        <a href={"/userlist"}>View Users</a>
                                    </NavText>
                                </NavItem>
                            </NavItem>
                        </SideNav.Nav>
                    </SideNav>
                            <div>
                                <div className="outer-reg-pane">
                                    <div className="reg-pane">
                                        <div>
                                            <input className="reg-input" type="text" name="userName"
                                                   placeholder="  Username"
                                                   onChange={this.onBlur} autoComplete="off"/>
                                        </div>
                                        <div>
                                            <input className="reg-input" name="password" type="password"
                                                   placeholder="  Password"
                                                   autoComplete="off" onChange={this.onBlur}/>
                                        </div>
                                    </div>
                                    <div className="reg-pane2">
                                        <div>
                                            <input className="reg-input" type="text" name="emailId"
                                                   placeholder="  Email Id"
                                                   onChange={this.onBlur} autoComplete="off"/>
                                        </div>
                                        <div>
                                            <input className="reg-input" name="confirmPassword" type="password"
                                                   placeholder="  Confirm Password"
                                                   autoComplete="off" onChange={this.onBlur}/>
                                        </div>
                                    </div>
                                    <div className={"dd-area"}>
                                        <select disabled={true} className={"reg-input"}>
                                            <option value={""}>Select Plan</option>
                                            <option value={"BAS"}> BASIC</option>
                                            <option value={"ADV"}>ADVANCED</option>
                                        </select>
                                    </div>
                                    <div className={"add-buttn"}>
                                        <input className="reg-buttn" type="submit" onClick={this.register}
                                               value="ADD USER"/>
                                    </div>
                                </div>
                                <ToastContainer
                                    position="bottom-right"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else
        {
            return (
                <Redirect to={{
                    pathname: '/'
                }}/>
            )
        }
    }

}

export default CreateUser;
