import React, {Component} from 'react';
import "./AAppBar.css"
import Redirect from "react-router-dom/es/Redirect";

const logo = require("./logo/H2Q_Logo.png");
const helpIcon = require("./icon/help-32.png");

class AAppBar extends Component
{

    constructor(props)
    {
        super(props);
        this.state = {
            Logged: true
        };
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout()
    {
        this.setState({
            Logged: false
        });
    }

    render()
    {
        if (!this.state.Logged)
        {
            return(
                <Redirect to={{
                    pathname: '/'
                }}/>
            )
        }
        else
        {
            return(
                <div className="main-div-appBar">
                    <div className="inner-app-bar">
                        <img className="logo-img" src={logo} alt={"Not support"}/>
                        <h2 className="nav-heading">H2Q</h2>
                        <div className="help-outer">
                            <img className="help-icon" src={helpIcon} alt={"Not support"}/>
                        </div>
                        <div className="logout">
                            <a className="logout-link" href={"#"} onClick={this.handleLogout}>LOGOUT</a>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
 export default AAppBar;
