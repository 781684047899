import React, {Component} from 'react';
import "./Computation.css"
import LAppBar from "../LoggedAppBar/LAppBar";
import Redirect from "react-router-dom/es/Redirect";
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer, toast} from "react-toastify";
import axios from "axios";

const ERROR_ACTIVIY_NOT_SPECIFIED = () => <div>Activity must be specified</div>;
const ERROR_ACTIVIY_INVALID = () => <div>Please specify a valid Activity</div>;

const ERROR_SUBSCRIPTION_NOT_SPECIFIED = () => <div>Subscription must be specified</div>;
const ERROR_INPUT_METHOD_NOT_SPECIFIED = () => <div>Input method must be specified</div>;
const ERROR_EXPECTED_OUTPUT_NOT_SPECIFIED = () => <div>Expected output must be specified</div>;

const WALKING_SPEED_LOW = 1.3;
const WALKING_SPEED_HIGH = 2.29;
const RUNNING_SPEED_LOW = 2.3;
const RUNNING_SPEED_HIGH = 6.4;
const CYCLING_SPEED_LOW = 4.77;
const CYCLING_SPEED_HIGH = 15.0;
const SWIMMING_SPEED_LOW = 0.5;
const SWIMMING_SPEED_HIGH = 1.25;
const SOCCER_SPEED_LOW = 0.9;
const SOCCER_SPEED_HIGH = 6.4;

const MAX_SWEAT_RATE = 4000;

const WALKING_ACTIVITY = 'walking';
const RUNNING_ACTIVITY = 'running';
const CYCLING_ACTIVITY = 'cycling';
const SWIMMING_ACTIVITY = 'swimming';
const SOCCER_ACTIVITY = 'soccer';
const SOCCER_GAME = 'soccer-game';

const SWEAT_LOSS = "SL";
const SWEAT_RATE = "SR";

class Computation extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
            {
                activity: '',
                airTempDegCelsius: 0,
                airTempDegCelsiusUnit: 'C', //constants to be used
                waterTempDegCelsius: 0,
                waterTempDegCelsiusUnit: 'C', //constants to be used

                subscription: '',
                relativeHumidityPercentage: 0, //constants to be used
                globeTempDegCelsius: 0,
                globeTempDegCelsiusUnit: 'C', //constants to be used
                cloudCover: '',

                inputMethod: '',
                energyExpKCal: 0,
                bodyWeight: 0,
                bodyWeightUnit: 'kg', //constants to be used
                distance: 0,
                distanceUnit: 'km', //constants to be used

                expOutput: '',
                duration: 0,
                durationUnit: '',
                result: '0',
                resultUnit: '',

                speed: 0,
                physiologicalWarn: false,
                errorServer: '',	//Cyril - what is the purpose of this??
                errorActivity: false,
                errorSubscription: false,
                errorInputMethod: false,
                errorOutput: false,
                redirectionFlag: false
            };

        this.setValue = this.setValue.bind(this);

        this.displayErrors = this.displayErrors.bind(this);
        this.disableFields = this.disableFields.bind(this);
        this.enableFields = this.enableFields.bind(this);
        this.convertFToC = this.convertFToC.bind(this);
        this.convertLbToKg = this.convertLbToKg.bind(this);
        this.convertMiToKm = this.convertMiToKm.bind(this);

        this.onActivityChange = this.onActivityChange.bind(this);
        this.onSubscriptionChange = this.onSubscriptionChange.bind(this);
        this.onInputMethodChange = this.onInputMethodChange.bind(this);
        this.onExpectedOutputChange = this.onExpectedOutputChange.bind(this);

        this.setStateVal = this.setStateVal.bind(this);
        this.reset = this.reset.bind(this);

        this.getSelectedInputMethod = this.getSelectedInputMethod.bind(this);
        this.getSelectedSubscription = this.getSelectedSubscription.bind(this);
        this.setResult = this.setResult.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.callAPI = this.callAPI.bind(this);
        this.checkSpeed = this.checkSpeed.bind(this);
    }

    convertFToC(F)
    {
        var C;
        C = ((F - 32) / 1.8);
        return C;
    }

    convertLbToKg(LB)
    {
        var KG;
        KG = (LB / 2.205);
        return KG;
    }

    convertMiToKm(MI)
    {
        var KM;
        KM = (MI * 1.609);
        console.log(KM);
        return KM;
    }

    componentWillMount()
    {
        if (sessionStorage.getItem("apiToken"))
        {
            if (sessionStorage.getItem("adminUser") == "F")
            {
                this.setState({
                    redirectionFlag: true
                });
            }
            else
            {
                this.setState({
                    redirectionFlag: false
                });
            }
        }
        else {
            this.setState({
                redirectionFlag: false
            });
        }
    }

    render()
    {
        if (this.state.redirectionFlag)
        {
            return(
                <div className="computation-main-div">
                    <div className="inner-computation-div">
                        <LAppBar/>
                        <div className="computation-div">
                            <div className="computation-div-header">
                                <h1 className="computation-title">Compute Sweat Loss/Rate</h1>
                            </div>
                            <div className="computation-field">
                                <div className="main-drops">
                                    <div className="singularity-a">
                                        <select className="my-custom-dd" name={"activity"} id="activity"
                                                value={this.state.activity}
                                                onChange={this.onActivityChange}>
                                            <option disabled={true} value="">Select Activity</option>
                                            <option value="walking">Walking</option>
                                            <option value="running">Running</option>
                                            <option value="cycling">Cycling</option>
                                            <option value="swimming">Swimming</option>
                                            <option value="soccer">Soccer</option>
                                            <option value="soccer-game">Soccer-Game</option>
                                        </select>
                                    </div>
                                    <div className="singularity-b" id={"singularity"}>
                                        <select className="my-custom-dd" name={"subscription"} id="subscription"
                                                value={this.state.subscription}
                                                onChange={this.onSubscriptionChange}>
                                            <option disabled={true} value="">Select Subscription</option>
                                            <option value="BAS">Basic</option>
                                            <option value="ADV">Advanced</option>
                                        </select>
                                    </div>
                                    <div className="singularity-c">
                                        <select className="my-custom-dd" name={"inputMethod"} id="inputMethod"
                                                value={this.state.inputMethod}
                                                onChange={this.onInputMethodChange}>
                                            <option disabled={true} value="">Select Input Method</option>
                                            <option value="MEAS">Measured</option>
                                            <option value="CALC">Calculated</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="input-div">
                                    <div className="input-div-holding">
                                        <div className="input-div-part-1">
                                            <div className="group-hold">
                                                <div className="inner-div">
                                                    <div className="u-input-group-1-1">
                                                        <input type="text" className="computation-input"
                                                               name="airTempDegCelsius" id="airTempDegCelsius"
                                                               autoComplete="off" placeholder="Air Temperature"
                                                               onChange={this.setStateVal}/>
                                                    </div>
                                                    <div className="u-dd">
                                                        <select className="my-custom-u-dd" name="airTempDegCelsiusUnit"
                                                                id="airTempDegCelsiusUnit"
                                                                value={this.state.airTempDegCelsiusUnit}
                                                                onChange={this.setStateVal}>
                                                            <option value="C">°C</option>
                                                            <option value="F">°F</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="inner-div">
                                                    <div className="u-input-group-1-2">
                                                        <input type="text" className="computation-input"
                                                               name="waterTempDegCelsius" id="waterTempDegCelsius"
                                                               autoComplete="off" placeholder="Water Temperature"
                                                               onChange={this.setStateVal} disabled={true}/>
                                                    </div>
                                                    <div className="u-dd">
                                                        <select className="my-custom-u-dd"
                                                                name="waterTempDegCelsiusUnit"
                                                                id="waterTempDegCelsiusUnit"
                                                                value={this.state.waterTempDegCelsiusUnit}
                                                                onChange={this.setStateVal} disabled={true}>
                                                            <option value="C">°C</option>
                                                            <option value="F">°F</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            width: "21%"
                                        }} className="input-div-part-2">
                                            <div className="input-inner-div" style={{
                                                display: "flex"
                                            }}>
                                                <div className="u-input" style={{
                                                    width: "441%"
                                                }}>
                                                    <input type="text" className="computation-input"
                                                           name="relativeHumidityPercentage"
                                                           id="relativeHumidityPercentage"
                                                           autoComplete="off" placeholder="Relative Humidity"
                                                           onChange={this.setStateVal} disabled={true}/>
                                                </div>
                                                <div className="u-input">
                                                    <input type="text" className="computation-input-unit"
                                                           name="energyExpKCalUnit" value={"%"} id="energyExpKCalUnit"
                                                           disabled={true}/>
                                                </div>
                                            </div>
                                            <div className="group-hold">
                                                <div className="inner-div">
                                                    <select className="my-custom-dd" name="cloudCover" id="cloudCover"
                                                            value={this.state.cloudCover}
                                                            onChange={this.setStateVal} disabled={true}>
                                                        <option disabled={true} value="">Select Solar Load</option>
                                                        <option value="FULL_SUN">Full Sun</option>
                                                        <option value="PARTLY_CLOUDY">Partly Cloudy</option>
                                                        <option value="CLOUDY">Cloudy</option>
                                                        <option value="GT">Enter Globe Temperature</option>
                                                    </select>
                                                </div>
                                                <div className="inner-div">
                                                    <div className="u-input-bb">
                                                        <input type="text" className="computation-input"
                                                               name="globeTempDegCelsius" id="globeTempDegCelsius"
                                                               autoComplete="off" placeholder="Globe Temperature"
                                                               onChange={this.setStateVal} disabled={true}/>
                                                    </div>
                                                    <div className="u-dd">
                                                        <select className="my-custom-u-dd"
                                                                name="globeTempDegCelsiusUnit"
                                                                id="globeTempDegCelsiusUnit"
                                                                value={this.state.globeTempDegCelsiusUnit}
                                                                onChange={this.setStateVal}
                                                                disabled={true}>
                                                            <option value="C">°C</option>
                                                            <option value="F">°F</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-div-part-3">
                                            <div className="u-input-ccc" id={"input-cc"}>
                                                <div className="u-input-c">
                                                    <input type="text" className="computation-input"
                                                           name="energyExpKCal" id="energyExpKCal"
                                                           autoComplete="off" placeholder="Energy Expenditure"
                                                           onChange={this.setStateVal} disabled={true}/>
                                                </div>
                                                <div className="u-input">
                                                    <input type="text" className="computation-input-unit"
                                                           name="energyExpKCalUnit" value={"kCal"}
                                                           id="energyExpKCalUnit"
                                                           disabled={true}/>
                                                </div>
                                            </div>
                                            <div className="group-hold">
                                                <div className="inner-div">
                                                    <div className="u-input-cc">
                                                        <input type="text" className="computation-input"
                                                               name="bodyWeight" id="bodyWeight"
                                                               autoComplete="off" placeholder="Body Weight"
                                                               onChange={this.setStateVal} disabled={true}/>
                                                    </div>
                                                    <div className="u-dd">
                                                        <select className="my-custom-u-dd" name="bodyWeightUnit"
                                                                id="bodyWeightUnit" value={this.state.bodyWeightUnit}
                                                                onChange={this.setStateVal}
                                                                disabled={true}>
                                                            <option value="kg">Kg</option>
                                                            <option value="lb">lb</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="inner-div">
                                                    <div className="u-input-ccc">
                                                        <input type="text" className="computation-input" name="distance"
                                                               id="distance"
                                                               autoComplete="off" placeholder="Distance"
                                                               onChange={this.setStateVal} disabled={true}/>
                                                    </div>
                                                    <div className="u-dd">
                                                        <select className="my-custom-u-dd" name="distanceUnit"
                                                                id="distanceUnit" value={this.state.distanceUnit}
                                                                onChange={this.setStateVal}
                                                                disabled={true}>
                                                            <option value="km">Km</option>
                                                            <option value="mi">mi</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="com-part">
                                    <div className="inner-com-part-2">
                                        <select className="my-custom-dd" name="expOutput" id="expOutput"
                                                value={this.state.expOutput}
                                                onChange={this.onExpectedOutputChange}>
                                            <option disabled={true} value="">Select Expected Output</option>
                                            <option value="SL">Sweat Loss</option>
                                            <option value="SR">Sweat Rate</option>
                                        </select>
                                    </div>
                                    <div className="duration-holding-div">
                                        <div className="u-input-ccc-1">
                                            <input type="text" className="computation-input" name="duration"
                                                   id="duration"
                                                   autoComplete="off" placeholder="Duration" onBlur={this.checkSpeed} onChange={this.setStateVal}
                                                   disabled={true}/>
                                        </div>
                                        <div className="u-input">
                                            <input type="text" className="computation-input-unit" name="durationUnit"
                                                   id="durationUnit" value="min" disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-area">
                                    <button onClick={this.validateForm} className="compute-buttn" type="submit" id="submit">
                                        Compute
                                    </button>
                                </div>
                            </div>
                            <ToastContainer
                                position="top-right"
                                autoClose={10000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable
                                pauseOnHover
                            />
                            <div className="result-field">
                                <div className="result-area">
                                    <label className="result-label"><span><b>Result</b></span></label>
                                    <input type="text" className="result-val" name="result" id="result"
                                           value={this.state.result} readOnly={true}/>
                                    <input type="reset" className="result-clear-buttn" value="Reset"
                                           onClick={this.reset}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else
        {
            return (
                <Redirect to={{
                    pathname: '/'
                }}/>
            )
        }
    }

    setValue(fieldName, fieldValue)
    {
        eval("document.getElementById('" + fieldName + "').value = '" + fieldValue + "'");
    }

    displayErrors = () => <div>{this.state.errorServer}</div>;

    disableFields(fieldName, fieldIndex)
    {
        if (fieldIndex != null)
        {
            eval("document.getElementById('" + fieldName + "').disabled = true");
        }
        else
        {
            eval("document.getElementById('" + fieldName + "').disabled = true");
        }
    }

    enableFields(fieldName, fieldIndex)
    {
        if (fieldIndex != null)
        {
            eval("document.getElementById('" + fieldName + "').disabled = false");
        }
        else
        {
            eval("document.getElementById('" + fieldName + "').disabled = false");
        }
    }

    onActivityChange(event)
    {

        this.setState
        ({
            activity: event.target.value
        });

        if (event.target.value == SWIMMING_ACTIVITY)
        {
            this.disableFields("airTempDegCelsius", 0);
            this.disableFields("airTempDegCelsiusUnit", 0);
            this.setValue("airTempDegCelsius", "");
            this.setState({
                airTempDegCelsius: "",
                airTempDegCelsiusUnit: "C"
            });

            if (document.getElementById("subscription").value == "ADV")
            {
                this.setState
                ({
                    subscription: ""
                });
            }
            document.getElementById("subscription").options[2].disabled = true; //Advanced Subscription

            this.enableFields("waterTempDegCelsius", 0);
            this.enableFields("waterTempDegCelsiusUnit", 0);
        }
        else
         {
            this.disableFields("waterTempDegCelsius", 0);
            this.disableFields("waterTempDegCelsiusUnit", 0);

            this.setValue("waterTempDegCelsius", "");
            this.setState({
                waterTempDegCelsius: "",
                waterTempDegCelsiusUnit: "C"
            });

            document.getElementById("subscription").options[2].disabled = false; //Advanced Subscription

            this.enableFields("airTempDegCelsius", 0);
            this.enableFields("airTempDegCelsiusUnit", 0);
        }
        if (event.target.value == SOCCER_GAME)
        {
            document.getElementById("inputMethod").options[1].disabled = true;
            if (document.getElementById("inputMethod").value == "MEAS")
            {
                this.setState
                ({
                    inputMethod: ""
                });
            }
            else
            {
                document.getElementById("distance").value = 10;
            }
            this.disableFields("duration");
            if (document.getElementById("expOutput").value == "SR")
            {
                this.setValue("duration", 90);
            }
            this.disableFields("distanceUnit");
            this.setState
            ({
                distance: 10,
                duration: 0
            });
            this.disableFields("distance");
        }
        else
        {
            this.setValue("duration", "");
            this.setState
            ({
                duration: 0
            });
            if (document.getElementById("expOutput").value == "SR")
            {
                this.enableFields("duration", 0);
                this.setValue("duration", "");
            }
            if (event.target.value == SOCCER_ACTIVITY)
            {
                document.getElementById("inputMethod").options[1].disabled = true;
                if (document.getElementById("inputMethod").value == "MEAS")
                {
                    this.setState
                    ({
                        inputMethod: ""
                    });
                }
            }
            else
            {
                document.getElementById("inputMethod").options[1].disabled = false;
            }
            document.getElementById("distance").value = '';
            this.setState
            ({
                distance: ''
            });
        }
    }

    onSubscriptionChange(event)
    {
        this.setState({
            subscription: event.target.value
        });

        if (event.target.value == "ADV")
        {
            this.enableFields("relativeHumidityPercentage", 0);
        }
        else if (event.target.value == "BAS")
        {
            this.disableFields("relativeHumidityPercentage", 0);
            this.setValue("relativeHumidityPercentage", "");
            this.disableFields("cloudCover", 0);
            this.setValue("cloudCover", "");
            this.setValue("globeTempDegCelsius", "");
            this.disableFields("globeTempDegCelsius", 0);
            this.disableFields("globeTempDegCelsiusUnit", 0);

            this.setState({
                cloudCover: "",
                relativeHumidityPercentage: "",
                globeTempDegCelsius:"",
                globeTempDegCelsiusUnit: "C"
            });
        }
    }

    onInputMethodChange(event)
    {
        this.setState({
            inputMethod: event.target.value
        });

        if (event.target.value == "MEAS")
        {
            this.enableFields("energyExpKCal", 0);
            this.setValue("energyExpKCal", "");
            this.disableFields("bodyWeight", 0);
            this.setValue("bodyWeight", "");
            this.disableFields("bodyWeightUnit", 0);
            this.disableFields("distance", 0);
            this.setValue("distance", "");
            this.disableFields("distanceUnit", 0);
            this.setState({
                distanceUnit: "",
                bodyWeightUnit: "",
                bodyWeight: 0,
                distance: 0
            });

        }
        else if (event.target.value == "CALC")
        {
            this.disableFields("energyExpKCal", 0);
            this.setValue("energyExpKCal", "");
            this.enableFields("bodyWeight", 0);
            this.enableFields("bodyWeightUnit", 0);
            if (this.state.activity == SOCCER_GAME)
            {
                this.disableFields("distance", 0);
                this.disableFields("distanceUnit", 0);
            }
            else
            {
                this.enableFields("distance", 0);
                this.enableFields("distanceUnit", 0);
            }

            this.setState({
                energyExpKCal: 0
            });
        }
    }

    onExpectedOutputChange(event)
    {
        this.setState
        ({
            [event.target.name]: event.target.value
        });

        if (event.target.value == SWEAT_LOSS)
        {
            this.setValue("duration", "");
            this.disableFields("duration", 0);
        } else if (event.target.value == SWEAT_RATE)
        {
            if (document.getElementById("activity").value == SOCCER_GAME)
            {
                this.setValue("duration", 90);
                this.disableFields("duration", 0);
            }
            else
            {
                this.enableFields("duration", 0);
            }
        }
    }

    setStateVal(e)
    {
        this.setState({[e.target.name]: e.target.value});

        if (!(document.getElementById("relativeHumidityPercentage").value == null || document.getElementById("relativeHumidityPercentage").value == '' || document.getElementById("relativeHumidityPercentage").value == ' '))
        {
            this.enableFields("cloudCover", 0);
        }
        else
        {
            this.disableFields("cloudCover", 0);
        }

        if (document.getElementById("cloudCover").value == 'GT')
        {
            this.enableFields("globeTempDegCelsiusUnit", 0);
            this.enableFields("globeTempDegCelsius", 0);
        }
        else
        {
            this.disableFields("globeTempDegCelsiusUnit", 0);
            this.disableFields("globeTempDegCelsius", 0);
            this.setValue("globeTempDegCelsius", "");
            this.setValue("globeTempDegCelsiusUnit", "C");
            this.setState({
                globeTempDegCelsius: 0,
                globeTempDegCelsiusUnit: ""
            });
        }

    }

    reset()
    {
        this.setState({
            activity: '',
            airTempDegCelsius: 0,
            airTempDegCelsiusUnit: 'C', //constants to be used
            waterTempDegCelsius: 0,
            waterTempDegCelsiusUnit: 'C', //constants to be used

            subscription: '',
            relativeHumidityPercentage: 0, //constants to be used
            globeTempDegCelsius: 0,
            globeTempDegCelsiusUnit: 'C', //constants to be used
            cloudCover: '',

            inputMethod: '',
            energyExpKCal: 0,
            bodyWeight: 0,
            bodyWeightUnit: 'kg', //constants to be used
            distance: 0,
            distanceUnit: 'km', //constants to be used

            expOutput: '',
            duration: 0,
            durationUnit: '',
            result: '0',
            resultUnit: '',

            errorServer: ''
        });
        this.setValue("activity", "");

        this.setValue("airTempDegCelsius", "");
        this.setValue("airTempDegCelsiusUnit", "C"); //constants to be used
        this.setValue("waterTempDegCelsius", "");
        this.disableFields("waterTempDegCelsius", 0);
        this.setValue("waterTempDegCelsiusUnit", "C"); //constants to be used
        this.disableFields("waterTempDegCelsiusUnit", 0);

        this.setValue("subscription", "");
        document.getElementById("subscription").options[2].disabled = false;

        this.setValue("relativeHumidityPercentage", "");
        this.disableFields("relativeHumidityPercentage", 0);
        this.setValue("globeTempDegCelsius", ""); //constants to be used
        this.disableFields("globeTempDegCelsius", 0);
        this.setValue("globeTempDegCelsiusUnit", "C");
        this.disableFields("globeTempDegCelsiusUnit", 0);
        this.setValue("cloudCover", "");
        this.disableFields("cloudCover", 0);

        this.setValue("inputMethod", "");
        this.setValue("energyExpKCal", "");
        this.disableFields("energyExpKCal", 0);
        this.setValue("bodyWeight", "");
        this.disableFields("bodyWeight", 0);
        this.setValue("bodyWeightUnit", "kg"); //constants to be used
        this.disableFields("bodyWeightUnit", 0);
        this.setValue("distance", "");
        this.disableFields("distance", 0);
        this.setValue("distanceUnit", "km");
        this.disableFields("distanceUnit", 0);

        this.setValue("expOutput", "");
        this.setValue("duration", "");
        this.disableFields("duration", 0);

        this.setValue("result", "0"); //constants to be used
    }

    getSelectedInputMethod()
    {
        if (this.state.inputMethod.toString() === 'MEAS') //Constants to be used
        {
            return 'M';
        }
        else if (this.state.inputMethod.toString() === 'CALC')
        {
            return 'C';
        }
    }

    getSelectedSubscription()
    {
        if (this.state.subscription.toString() === 'BAS')
        {
            return 'B';
        }
        else if (this.state.subscription.toString() === 'ADV')
        {
            return 'A';
        }
    }

    setResult(result)
    {
        this.setState({
            result: result
        });
    }

    validateForm()
    {
        var bValidInput = true;
        var speedCheckStatus = false;

        if (this.state.activity == '')
        {
            toast.error(<ERROR_ACTIVIY_NOT_SPECIFIED/>);
            bValidInput = false;
        }
        else if (!(this.state.activity == WALKING_ACTIVITY || this.state.activity == RUNNING_ACTIVITY
            || this.state.activity == CYCLING_ACTIVITY
            || this.state.activity == SWIMMING_ACTIVITY
            || this.state.activity == SOCCER_ACTIVITY
            || this.state.activity == SOCCER_GAME))
        {
            toast.error(<ERROR_ACTIVIY_INVALID/>);
            bValidInput = false;
        }

        if (this.state.subscription === '')
        {
            toast.error(<ERROR_SUBSCRIPTION_NOT_SPECIFIED/>);
            bValidInput = false;
        }

        if (this.state.inputMethod === '')
        {
            toast.error(<ERROR_INPUT_METHOD_NOT_SPECIFIED/>);
            bValidInput = false;
        }

        if (this.state.expOutput === '')
        {
            toast.error(<ERROR_EXPECTED_OUTPUT_NOT_SPECIFIED/>);
            bValidInput = false;
        }

        if (this.state.expOutput == SWEAT_LOSS)
        {
            speedCheckStatus = true;
        }

        if (document.getElementById("activity").value != SOCCER_GAME)
        {
            if (document.getElementById("inputMethod").value == 'CALC')
            {
                if (document.getElementById("duration").value != 0)
                {
                    let distance = document.getElementById("distance").value;
                    let duration = document.getElementById("duration").value;

                    if (this.state.distanceUnit == 'km')
                    {
                        distance = distance * 1000;
                    }
                    else
                    {
                        distance = this.convertMiToKm(distance);
                        distance = distance * 1000;
                    }
                    duration = duration * 60;
                    let speed = distance / duration;
                    speedCheckStatus = this.checkSpeed(this.state.activity, speed);
                }
            }
            else
            {
                speedCheckStatus = true;
            }
        }
        else
        {
            speedCheckStatus = true;
        }
        if (bValidInput)
        {
            if (speedCheckStatus)
            {
                this.callAPI();
            }
        }
    }

    callAPI()
    {
        let F;
        let C;
        var airTempDegCelsius;
        var waterTempDegCelsius;
        var globeTempDegCelsius;
        var weightKg;
        var distanceKm;

        let url = process.env.REACT_APP_BASE_URL + '/h2q-api';
        if (this.state.expOutput.toString() === SWEAT_LOSS) {
            url = url + "/compute-sweat-loss/" + this.state.activity.toString();
        } else if (this.state.expOutput.toString() === SWEAT_RATE) {
            url = url + "/compute-sweat-rate/" + this.state.activity.toString();
        }

        //air Temp---->
        if (this.state.airTempDegCelsiusUnit == 'F')
        {
            F = this.state.airTempDegCelsius;
            C = this.convertFToC(F);
            airTempDegCelsius = C;
        }
        else
        {
            airTempDegCelsius = this.state.airTempDegCelsius;
        }
        //water Temp---->
        if (this.state.waterTempDegCelsiusUnit == 'F')
        {
            F = this.state.waterTempDegCelsius;
            C = this.convertFToC(F);
            waterTempDegCelsius = C;
        }
        else
        {
            waterTempDegCelsius = this.state.waterTempDegCelsius;
        }

        let relativeHumidityPercentage = this.state.relativeHumidityPercentage;
        //globe Temp------->
        if (this.state.globeTempDegCelsiusUnit == 'F')
        {
            F = this.state.globeTempDegCelsius;
            C = this.convertFToC(F);
            globeTempDegCelsius = C;
        }
        else
        {
            globeTempDegCelsius = this.state.globeTempDegCelsius;
        }

        let cloudCover = this.state.cloudCover;

        let energyExpKCal = this.state.energyExpKCal;

        if (this.state.bodyWeightUnit == 'lb')
        {
            let LB = this.state.bodyWeight;
            let KG = this.convertLbToKg(LB);
            weightKg = KG;
        }
        else
        {
            weightKg = this.state.bodyWeight;
        }

        if (this.state.distanceUnit == 'mi')
        {
            let MI = this.state.distance;
            let KM = this.convertMiToKm(MI);
            distanceKm = KM;
        }
        else
        {
            distanceKm = this.state.distance;
        }

        let expectedOutput = this.state.expOutput;
        let durationMin = this.state.duration;

        let token = sessionStorage.getItem("apiToken");

        let headers =
            {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            };

        let payload;
        if (this.getSelectedSubscription() === 'B' && this.getSelectedInputMethod() === 'M')
        {
            url = url + '/BAS/MEAS';
            if (this.state.activity.toString() === SWIMMING_ACTIVITY)
            {
                payload = {
                    waterTempDegCelsius,
                    energyExpKCal,
                    durationMin
                };
            }
            else
            {
                payload = {
                    airTempDegCelsius,
                    energyExpKCal,
                    durationMin
                };
            }
        }
        else if (this.getSelectedSubscription() === 'B' && this.getSelectedInputMethod() === 'C')
        {
            url = url + '/BAS/CALC';
            if (this.state.activity.toString() === SWIMMING_ACTIVITY)
            {
                payload = {
                    waterTempDegCelsius,
                    weightKg,
                    distanceKm,
                    durationMin
                };
            }
            else
            {
                payload = {
                    airTempDegCelsius,
                    weightKg,
                    distanceKm,
                    durationMin
                };
            }
        }
        else if (this.getSelectedSubscription() === 'A' && this.getSelectedInputMethod() === 'M')
        {
            url = url + '/ADV/MEAS';
            payload = {
                airTempDegCelsius,
                relativeHumidityPercentage,
                energyExpKCal,
                globeTempDegCelsius,
                cloudCover,
                durationMin
            }
        }
        else if (this.getSelectedSubscription() === 'A' && this.getSelectedInputMethod() === 'C')
        {
            url = url + '/ADV/CALC';
            payload = {
                airTempDegCelsius,
                weightKg,
                distanceKm,
                relativeHumidityPercentage,
                globeTempDegCelsius,
                cloudCover,
                durationMin
            }
        }

        var self = this;
        axios
            .post(url, payload, {headers: headers})
            .then(function (response)
            {
                if (response.status === 200)
                {
                    if (response.data.responseCode === 200)
                    {
                        let result = "";
                        if (expectedOutput.toString() === 'SL')
                        {
                            result = (response.data.sweatLossMl).toString() + ' mL';
                            self.setResult(result);
                            toast.success("Computed Successfully");
                        }
                        else if (expectedOutput.toString() === 'SR')
                        {
                            result = (response.data.sweatLossMlPerHour);
                            if (result <= MAX_SWEAT_RATE)
                            {
                                result = (response.data.sweatLossMlPerHour).toString() + ' mL/h';
                                self.setResult(result);
                                toast.success("Computed Successfully");
                            }
                            else
                            {
                                toast.warn("Warning: The Sweating rate is greater than the maximum limit. Please check and re-enter the parameters");
                                result = '0';
                                self.setResult(result);
                            }
                        }
                    }
                    else if (response.data.errorCode == 400)
                    {
                        self.setState({
                            errorServer: response.data.errorMessage
                        });
                        toast.error(self.displayErrors);
                    }
                }
            })
            .catch(function (error) {
                if (error.response.status == 401)
                {
                    self.setState({
                        errorServer: error.response.data.message
                    });
                    toast.error(self.displayErrors);
                    console.log(error.response);
                }
            })
    }

    checkSpeed(activity, speed)
    {
        var status = false;

        if (activity == WALKING_ACTIVITY)
        {
            if (speed >= WALKING_SPEED_LOW && speed <= WALKING_SPEED_HIGH)
            {
                status = true;
            }
            else
            {
                toast.warn("Warning: The speed of the selected activity is outside the permitted limits. Please check and re-enter the distance and time.");
                status = false;
            }
        }
        else if (activity == RUNNING_ACTIVITY)
        {
            if (speed >= RUNNING_SPEED_LOW && speed <= RUNNING_SPEED_HIGH)
            {
                status = true;
            }
            else
            {
                toast.warn("Warning: The speed of the selected activity is outside the permitted limits. Please check and re-enter the distance and time.");
                status = false;
            }
        }
        else if (activity == CYCLING_ACTIVITY)
        {
            if (speed >= CYCLING_SPEED_LOW && speed <= CYCLING_SPEED_HIGH)
            {
                status = true;
            }
            else
            {
                toast.warn("Warning: The speed of the selected activity is outside the permitted limits. Please check and re-enter the distance and time.");
                status = false;
            }
        }
        else if (activity == SWIMMING_ACTIVITY)
        {
            if (speed >= SWIMMING_SPEED_LOW && speed <= SWIMMING_SPEED_HIGH)
            {
                status = true;
            }
            else
            {
                toast.warn("Warning: The speed of the selected activity is outside the permitted limits. Please check and re-enter the distance and time.");
                status = false;
            }
        }
        else if (activity == SOCCER_ACTIVITY)
        {
            if (speed >= SOCCER_SPEED_LOW && speed <= SOCCER_SPEED_HIGH)
            {
                status = true;
            }
            else
            {
                toast.warn("Warning: The speed of the selected activity is outside the permitted limits. Please check and re-enter the distance and time.");
                status = false;
            }
        }

        return status;
    }
}

export default Computation;