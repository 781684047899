import React, {Component} from 'react';
import AppBar from "../AppBar/AppBar";
import "./Login.css"
import axios from "axios";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Redirect from "react-router-dom/es/Redirect";
import Popup from "reactjs-popup";

const app_logo = require("./logo/H2Q_Logo.png");
const ErrorUsername = () => <div>Username must be specified</div>;
const ErrorPassword = () => <div>Password must be specified</div>;
const ErrorLogin = () => <div>Invalid user</div>;
const ErrorCheckTerms = () => <div>Please check the terms of use</div>;


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            checkEnable: false,
            redirectionFlag: false,
            error_username: false,
            error_password: false
        };

        sessionStorage.clear();

        this.login = this.login.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onRedirection = this.onRedirection.bind(this);
        this.checkTerms = this.checkTerms.bind(this);
        this.enableFields = this.enableFields.bind(this);
        this.disableFields = this.disableFields.bind(this);

    }

    componentDidMount() {
        var x = document.getElementById("login-buttn");
        x.disabled = true;
        if (this.state.checkEnable) {
            this.disableFields("agree", 0);
        }
    }

    login() {
        if (this.state.checkEnable) {
            let userName = this.state.userName;
            let password = this.state.password;

            var self = this;
            if ((userName === '' || userName === ' ') || ((password === '') || password === ' ')) {
                if (userName === '' || userName === ' ') {
                    this.setState({error_username: true});
                    toast.error(<ErrorUsername/>);
                }
                if (password === '' || password === ' ') {
                    this.setState({error_password: true});
                    toast.error(<ErrorPassword/>);
                }
            }
            else {
                let headers = {
                    "Content-Type": "application/json"
                };
                let apiBaseUrl = process.env.REACT_APP_BASE_URL + "/token?userName=" + userName + "&password=" + password;

                axios
                    .post(apiBaseUrl, {headers: headers})
                    .then(function (response) {
                            if (response.status === 200) {
                                if (response.data === "no token found") {
                                    toast.error(<ErrorLogin/>);
                                }
                                else {
                                    sessionStorage.setItem("apiToken", response.data);
                                    self.onRedirection();
                                }
                            }
                        }
                    )
                    .catch(function (error) {
                        console.log(error.response);
                    });
            }
        }
        else {
            toast.error(<ErrorCheckTerms/>);
        }
    }

    onRedirection() {
        this.setState({
            redirectionFlag: true
        });
    }

    onBlur(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        if (this.state.redirectionFlag) {
            if (this.state.userName == process.env.REACT_APP_ADMIN_UNAME) {
                sessionStorage.setItem("adminUser", "T");
                return (
                    <Redirect to={{
                        pathname: '/admin'
                    }}/>
                )
            }
            else {
                sessionStorage.setItem("adminUser", "F");
                return (
                    <Redirect to={{
                        pathname: '/computation'
                    }}/>
                )
            }
        }
        return (
            <div className="main-login-div">
                <AppBar/>
                <div className="outer-login-pane">
                    <div className="logo-div">
                        <img className="logo-login-page" src={app_logo} alt={"Not support"}/>
                    </div>
                    <div className="login-pane">
                        <div>
                            <input className="login-input" type="text" name="userName" placeholder="Username"
                                   onChange={this.onBlur} autoComplete="off"/>
                        </div>
                        <div>
                            <input className="login-input" name="password" type="password" placeholder="*********"
                                   autoComplete="off" onChange={this.onBlur}/>
                        </div>
                        <div>
                            <button className="login-buttn" type="submit" id={"login-buttn"}
                                    onClick={this.login}> SIGN-IN
                            </button>
                        </div>
                        <Popup contentStyle={{
                            width: '700px',
                            top: '122px',
                            left: '396.609px',
                            textAlign: 'justify'
                        }} trigger={<a href={"#"} className={"pop-up-label"}> Terms of use</a>}
                               position="top center">
                            <div className={"pop-content"}>
                                <p style={{
                                    textAlign: 'center'
                                }}><span><u>H2Q Web App Disclaimer and Terms of Use</u></span></p>
                                <p>The H2Q algorithms are
                                    intended to provide athletes, coaches, and researchers with simple, accurate, and
                                    actionable predictions of individual exercise sweat losses when used as a
                                    stand-alone
                                    responsive web application. H2Q can be used to forecast, predict real-time, and
                                    post-workout sweat losses. The H2Q algorithms are pending patent approval. The H2Q
                                    equations embedded within the algorithms are a Trade Secret of Sports Science
                                    Synergy,
                                    LLC. H2Q and its content may not be reproduced or used for unauthorized purposes
                                    without
                                    prior written permission, which may be obtained by submitting a written request to:
                                    Sports Science Synergy, LLC, 9 Damico Drive, Franklin, MA 02038, Attn: Dr. Samuel N.
                                    Cheuvront.
                                </p>
                                <p>As with any model, H2Q prediction accuracy depends upon accurate inputs. Predictions
                                    are not a substitute for gold standard measurements (see: Cheuvront SN and Kenefick
                                    RW. CORP: Improving the status quo for measuring whole body sweat losses. Journal of
                                    Applied Physiology, 123(3): 632-636, 2017). H2Q is not a mobile health app.
                                </p>
                                <p>
                                    H2Q is provided “as is.” Sports Science Synergy, LLC disclaims all warranties,
                                    express or implied, including the implied warranties of merchantability, fitness for
                                    a particular purpose and non-infringement, to the extent permitted by applicable
                                    law. Sports Science Synergy, LLC makes no representations or warranties as to the
                                    quality, suitability or adequacy of the H2Q algorithms for any purpose or use.
                                </p>
                                <p style={{textAlign: 'center'}}><input type="checkbox" name="checkbox" value="check"
                                                                        checked={this.state.checkEnable}
                                                                        onChange={this.checkTerms} id="agree"/> I have
                                    read and agree to the Terms of use</p>
                            </div>
                        </Popup
                        >
                    </div>
                </div>
                <ToastContainer
                    position="bottom-center"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </div>
        )
    }

    enableFields(fieldName, fieldIndex) {
        if (fieldIndex != null) {
            eval("document.getElementById('" + fieldName + "').disabled = false");
        }
        else {
            eval("document.getElementById('" + fieldName + "').disabled = false");
        }
    }

    disableFields(fieldName, fieldIndex) {
        if (fieldIndex != null) {
            eval("document.getElementById('" + fieldName + "').disabled = true");
        }
        else {
            eval("document.getElementById('" + fieldName + "').disabled = true");
        }
    }

    checkTerms() {
        let checkValue = document.getElementById("agree").checked;
        if (!this.state.checkEnable) {
            if (checkValue) {
                this.enableFields("login-buttn", 0);
                this.disableFields("agree", 0);
                this.setState({
                    checkEnable: true
                });
                document.getElementById("agree").checked = this.state.checkEnable;
            }
            else {
                this.disableFields("login-buttn", 0);
            }
        }
        else {
            this.disableFields("agree", 0);
        }
    }
}

export default Login;