import React, {Component} from 'react';
import "./AppBar.css"

const logo = require("./logo/H2Q_Logo.png");
const helpIcon = require("./icon/help-32.png");

class AppBar extends Component
{
    render()
    {
        return (
            <div className="main-div-appBar">
                <div className="inner-app-bar">
                    <img className="logo-img" src={logo} alt={"Not support"}/>
                    <h2 className="nav-heading">H2Q</h2>
                    <div className="help-outer-a">
                        <img className="help-icon" src={helpIcon}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppBar;